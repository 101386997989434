import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import edjsHTML from 'editorjs-html'
import moment from 'moment'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import SEO from '../../components/seo'
import GeneralLayout from '../../components/layouts/general'
import AppLayout from '../../components/layouts/appLayout'
import { Heading1 } from '../../components/common/typography'
import bp from '../../components/common/breakpoints'
import {
  BLACK_COLOR_RGB,
  PRIMARY_COLOR,
  BLACK_COLOR
} from '../../components/common/color'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import { getBlogPostById } from '../../utils/requests'
import matter from 'gray-matter'
import { Remarkable } from 'remarkable'

const remarkable = new Remarkable()

deckDeckGoHighlightElement()

const Content = styled.div`
  padding: 1rem 0 3rem;
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
`

const PostDate = styled.span`
  color: ${BLACK_COLOR_RGB(0.7)};
`

const BackToBlogLink = styled(Link)`
  position: relative;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  &:hover {
    &::before {
      background-color: ${BLACK_COLOR};
    }
  }
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
  gap: 1rem;
  ${bp[3]} {
    flex-direction: row;
    align-items: center;
  }
`
const StyledHeading = styled(Heading1)`
  margin: 0;
  padding: 0;
`

const ApplyTo = ({ opportunity }) => {
  const [text, setText] = useState('Apply for the job')

  const getFileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      cb({ filename: file.name, data: reader.result, contentType: file.type })
    }
    reader.onerror = () => cb(null)
  }

  const handleAttachment = (document) => {
    if (document) {
      const fixStart = document.data.search('base64,') + 7

      const content = document.data.slice(fixStart, document.data.length)
      const filename = document.filename
      const encoding = 'base64'

      sendEmail([{ filename, content, encoding }])
    }
  }

  const handleAttachmentOnChange = (event) => {
    const [file] = event.target.files
    getFileToBase64(file, handleAttachment)
  }

  const sendEmail = (attachments) => {
    var messageBody = `Apply to ${opportunity} opportunity`

    fetch(
      'https://eka4p3o2q1.execute-api.us-west-2.amazonaws.com/Production/submit',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject: 'careers opportunity',
          body: messageBody,
          attachments: attachments,
        }),
      }
    )
      .then(() => {
        setText('Thanks for applying!')
      })
      .catch((res) => {
        console.error(res)
      })
  }

  return (
    <>
      <label
        style={{
          display: 'block',
          backgroundColor: PRIMARY_COLOR,
          color: 'white',
          fontWeight: 'bold',
          fontFamily: 'source code pro',
          fontSize: '1rem',
          borderRadius: '4px',
          padding: '0.75rem 1rem',
          textAlign: 'center',
          cursor: 'pointer',
          width: '16rem'
        }}
        htmlFor="resume"
      >
        {text}
      </label>
      <input
        style={{ display: 'none' }}
        type="file"
        id="resume"
        name="resume"
        onChange={handleAttachmentOnChange}
      />
    </>
  )
}

const BlogAwsView = ({ awsSlug }) => {
  const [post, setPost] = useState(null)
  useEffect(() => {
    const fetchBlogPostBySlug = async () => {
      try {
        const response = await getBlogPostById(awsSlug)

        if (!response.success) {
          navigate('/404')
          return
        }

        // validate response.data.Item.data if is JSON or MD
        let html
        const content = response.data.Item.data

        if (typeof content === 'string') {
          // Markdown pages (legacy)
          const { content } = matter(response.data.Item.data)
          html = remarkable.render(content)
        } else {
          // EditorJS pages
          const edjsParser = edjsHTML()
          html = edjsParser.parse(response.data.Item.data).join('')
        }

        setPost({
          ...response.data.Item,
          date: moment(response.data.Item.date).format('MMMM Do, YYYY'),
          html,
        })
      } catch (err) {
        navigate('/404')
      }
    }
    fetchBlogPostBySlug()
  }, [awsSlug])

  if (!post) {
    return null
  }

  const { date, title, html } = post

  return (
    <AppLayout>
      <SEO title={title} canonical={`careers-opportunity/${awsSlug}`} />
      <Global
        styles={css`
          .blog-html {
            font-family: var(--primary-font);
            margin: 1rem 0;

            h1 {
              margin: 0.5rem 0;
            }

            h4 {
              margin: 0.25rem 0;
            }

            * {
              line-height: 1.5;
            }

            img {
              max-width: 800px;
              width: 100%;
              display: block;
              margin: 0 auto;
            }

            deckgo-highlight-code {
              display: block;
              margin: 0 auto;
              max-width: 800px;
            }
          }
        `}
      />
      <Content>
        <BackToBlogLink to="/careers-opportunity">
          Back to Careers
        </BackToBlogLink>
        <HeaderContainer>
          <StyledHeading>{title}</StyledHeading>
          <ApplyTo opportunity={title} />
        </HeaderContainer>
        <PostDate>
          <strong>Published At: </strong>
          {date}
        </PostDate>
        <div
          className="blog-html"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </Content>
    </AppLayout>
  )
}

export default BlogAwsView
